import React from "react";

// Customizable Area Start
import {
    Typography,
    Box,
    Table,
    TableContainer,
    Tabs,
    Tab,
    TextField,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Radio,
} from "@material-ui/core";
import AddLogisticalController, {
    Props,
} from "./AddLogisticalController";
import Usernavbar from "../../../components/src/UserNavbar.web";
import SideBar from "../../../components/src/SideBar.web"
import { saveImg } from "./assets";
import { Pagination, PaginationItem } from "@mui/material";
// Customizable Area End

export default class AddLogistical extends AddLogisticalController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    /*istanbul ignore next*/
    Addbrands = () => {
        const {allData} = this.state;

        const renderProductCatagory = () => {
            const currentData = this.state.allData[this.state.activeTab] || []; 
        
            return (
                <>
                    <Box style={{ borderBottom: '1px solid #E1E7EF' }}>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead style={webStyle.TableHeads}>
                                    <TableRow style={{ borderBottom: 'none' }}>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss1, fontFamily: "Inter", borderBottom: "none" }}>
                                            Code
                                        </TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss2, fontFamily: "Inter", borderBottom: "none" }}>
                                            Description
                                        </TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={webStyle.TBodys}>
                                <TableRow style={{ height: "30px" }}></TableRow>
                                    {currentData.length > 0 ? (
                                        currentData.map((item:any, index:number) => (
                                            <TableRow key={index} data-testId="Rowclick" style={{ height: "45px" }}>
                                                <TableCell style={{ width: "0%", borderBottom: "none", borderRight: "1px solid #E7E2F0" }}></TableCell>
                                                <TableCell
                                                    style={{
                                                        ...webStyle.Cells1,
                                                        borderTop: "1px solid #E7E2F0",
                                                        backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent',
                                                    }}
                                                >
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '8px',
                                                        }}
                                                    >
                                                        <Radio
                                                            style={{
                                                                color: this.state.selectedRow === index ? '#FF8533' : 'inherit',
                                                            }}
                                                            data-testid={`radio-button-${index}`}
                                                            checked={this.state.selectedId.includes(item.id)}
                                                            onChange={() => this.handleRadioClick(index, item.id)}
                                                        />
                                                        <div style={{ backgroundColor: "#FFFFFF" }}>
                                                            <TextField
                                                                variant="outlined"
                                                                size="small"
                                                                defaultValue={item.attributes.code}
                                                                style={{ width: "100px", backgroundColor: '#FFFFFF' }}
                                                            />
                                                        </div>
                                                    </Box>
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        ...webStyle.Cells,
                                                        borderTop: "1px solid #E7E2F0",
                                                        backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent',
                                                    }}
                                                >
                                                    {item.attributes.description}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    style={{
                                                        borderTop: "1px solid #E7E2Ef",
                                                        backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent',
                                                    }}
                                                ></TableCell>
                                                <TableCell
                                                    style={{
                                                        width: "0%",
                                                        borderBottom: "1px solid #E7E2Ef",
                                                        borderTop: "1px solid #E7E2Ef",
                                                        borderRight: "1px solid #E7E2EF",
                                                        backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent',
                                                    }}
                                                ></TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={5} style={{ textAlign: 'center', color: '#64748B' }}>
                                                No data available for the selected tab.
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow>
                                    <TableCell style={{ width: "1%", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #E7E2F0" }} colSpan={5}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <span style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: 400, color: "#64748B" }}>{this.getResultText(this.state.currentPage)}</span>
                                                <Pagination
                                                    count={Math.ceil(this.state.totalResults / this.state.resultperPage)}
                                                    page={this.state.currentPage}
                                                    siblingCount={0}
                                                    boundaryCount={1}
                                                    renderItem={(item) => (
                                                        <PaginationItem
                                                            {...item}
                                                            style={{
                                                                textDecoration: 'underline',
                                                                borderRadius: 0,
                                                                background: "none",
                                                                fontFamily: "Inter",
                                                                fontSize: "12px",
                                                                fontWeight: 400,
                                                                color: "#979797"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ width: "1%", borderLeft: "1px solid #E7E2F0", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                        </TableRow>
                                        <TableRow style={{ height: "30px" }}></TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box onClick={this.state.productTags?this.addLogisticalTagApiCall:this.editLogisticalCodeApiCall} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
                    </Box>
                </>
            );
        };
        
        return (
            <Box style={{ width: "94%", ...webStyle.MainContainer }}>
                <Box style={webStyle.Headers}>
                    <Typography style={webStyle.Headingss}>
                        Add Logistical Code
                    </Typography>
                </Box>
                <Box style={{ ...webStyle.formGrid, marginTop: "30px", marginBottom: "30px" }}>
                    <Box style={webStyle.formItem}>
                        <Box style={{ display: "flex" }}>
                            <Typography style={webStyle.label}>Product Tag</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                        </Box>
                        <TextField
                            value={this.state.productTags || this.state.editdata}
                            onChange={(e) => this.handleEditChange(e.target.value)}
                            inputProps={{
                                style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter", color: "#BCC1CA", },
                            }} style={{
                                ...webStyle.formField,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter", color: "#1E293B", },
                            }}
                        />
                    </Box>
                </Box>
                <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
                    <Box style={{ ...webStyle.navTabContainer }}>
                        <Box
                            style={{
                                flexGrow: 1,
                                display: "flex",
                                overflowX: "auto",
                            }}
                        >
                            <Tabs value={this.state.value} onChange={(event, newValue) => this.handleTabChange(newValue)} TabIndicatorProps={{
                                style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
                             }}>
                                {Object.keys(allData).map((key, index) => (
                                <Tab
                                    key={index}
                                    onClick={() => this.handleTabChange(index)} 
                                    style={{ textTransform: "none", height: "58px", minWidth:"56px" }}
                                    label={
                                        <Typography
                                            style={this.state.value === index ? webStyle.activeTab : webStyle.inactiveTab}
                                        >
                                            {this.state.value === index ? key : key.charAt(0).toUpperCase()}
                                        </Typography>
                                    }
                                />
                            ))}
                            </Tabs>
                        </Box>
                    </Box>
                    <TableContainer>
                        <Table aria-label="simple table">
                            {renderProductCatagory()}
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        );
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                <Usernavbar />
                <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
                    {this.Addbrands()}
                </SideBar>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    MainContainer: {
        marginLeft: "47px",
        fontFamily: "Inter",
    },
    Headers: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "20px 0px",
        fontFamily: "Inter",
    },
    Headingss: {
        fontFamily: "Inter",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "1.5",
        textAlign: "left" as React.CSSProperties["textAlign"]
    },
    formGrid: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "20px",
        alignItems: "start",
    },
    formItem: {
        display: 'flex' as 'flex',
        flexDirection: 'column' as 'column' | 'row-reverse' | 'column' | 'column-reverse',
        width: '100%' as string,
    },
    AddType: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#FF6600",
        marginLeft: "8px"
    },
    formField: {
        width: '100%',
        height: '40px',
        borderRadius: '8px',
        background: '#F3F4F6',
        boxSizing: 'border-box' as 'border-box',
    } as React.CSSProperties,
    label: {
        marginBottom: "5px",
        fontSize: "14px",
        fontWeight: 700,
        color: "#334155",
    },
    textFieldStyle: {
        fontSize: "14px",
        fontWeight: 400,
        backgroundColor: "#F3F4F6",
        border: "none",
        borderRadius: "8px",
        boxShadow: "none",
    },
    uploadContainer: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
    },
    uploadButton: {
        backgroundColor: "#ff6600",
        color: "#fff",
        textTransform: "none",
        padding: "6px 12px",
        borderRadius: "8px",
    },
    saveButton: {
        margin: "16px 20px 16px 0px",
        boxShadow: "none",
    },
    formHeading: {
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: 600,
        marginBottom: "20px",
    },
    CheckoutButton: {
        backgroundColor: "#ff6600",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        color: "white",
        borderRadius: "13px",
        height: "44px",
        width: "max-content",
    },
    navTabContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: "58px",
        borderBottom: "1px solid #E1E7EF"
    },
    activeTab: {
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '24px',
        textAlign: 'center' as 'center',
        color: '#EE6900',
    },
    inactiveTab: {
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left' as 'left',
        color: '#565E6C',
    },
    Title: {
        fontFamily: 'Inter',
        fontSize: '20px',
        fontWeight: 600,
        marginBottom: "20px"
    },
    TBodys: {
        padding: "20px",
    },
    Cells1: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        color: "#323842",
    },
    Cells: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 400,
        color: "#323842",
    },
    TableHeads: {
        backgroundColor: "#f3f4f6",
        width: "100%"
    },
    TableCellss: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "left",
        color: "#565E6C",
        backgroundColor: "#F3F4F6",
    },
    TableCellss1: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "left",
        color: "#565E6C",
        backgroundColor: "#F3F4F6",
        paddingRight: "250px",
        paddingLeft: "70px"
    } as React.CSSProperties,
    TableCellss2: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "left",
        color: "#565E6C",
        backgroundColor: "#F3F4F6",
        paddingRight: "100px",
    } as React.CSSProperties,
    TableCellss3: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "left",
        color: "#565E6C",
        backgroundColor: "#F3F4F6",
        paddingLeft: "220px",
    } as React.CSSProperties,
}
// Customizable Area End